import axios from 'axios'

const url = window.location.host.split('.')[0] === 'qa' ? process.env.REACT_APP_SERVER_URL_QA : process.env.REACT_APP_SERVER_URL 

const getCompanies = async () => {
    const response = await axios.get(url+'/api/organization/getOrganizations')
    return response.data
}

export default {
    getCompanies
}
import { IonModal } from "@ionic/react"
import { UserProfileComponent } from "../components"

export const UserProfileModal = ({open, handleClose}:{open: boolean, handleClose: () => void}) => {
    return (
        <IonModal
            isOpen={open}
            onWillDismiss={() => {handleClose()}}
            backdropDismiss={false}
            className='users-management-modal'
        >
            <UserProfileComponent
                handleClose={handleClose}
            />
        </IonModal>
    )
}
import { IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonInput, IonRow, IonSpinner, IonToolbar } from '@ionic/react'
import { Mic, Send } from '../../icons'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useAuthContext } from '../../context/Auth.context'
import { useMessagesContext } from '../../context/Messages.context'
import { MessageFormat } from '../../interfaces/Messages.interface'
import { audioChat, chat } from '../../routes/ia.routes'
import { useFilesContext } from '../../context/Files.context'
import PDFModal from '../../modals/PDF.modal'
import { stop, barcodeOutline } from 'ionicons/icons'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useAvatarContext } from '../../context/Avatar.context'
import { useSocketContext } from '../../context/Socket.context'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { TextInputModal } from '../../modals/TextInput.modal'


const ChatContainer = () => {
    const {userId, initials, isSmartPhone, userInvited, user, isAuth} = useAuthContext()
    const {waitAudio, messageAudio, setMessageAudio} = useSocketContext()
    const {messages, avatarWeb, unableChat} = useMessagesContext()
    const {fileSelected, setDisabledInput, setWaitWhileMessage} = useFilesContext()
    const {elevenLabsVoiceId, nameAvatar, setEnableAvatar, enableAvatar, avatarElement, setAvatarElement, optimized, setOptimized} = useAvatarContext()
    const [messagesToUse, setMessagesToUse] = useState<MessageFormat[]>([])
    const [waitResponse, setWaitResponse] = useState(false)
    const [avatarSpeaking, setAvatarSpeaking] = useState(false)
    const [avatarPaused, setAvatarPaused] = useState(true)
    const {transcript,listening} = useSpeechRecognition()
    const [openFileReader, setOpenFileReader] = useState(false)
    const [message, setMessage] = useState('')
    const [audioMessage, setAudioMessage] = useState('')
    const [referenceSelected, setReferenceSelectec] = useState<{page_content: string, metadata: any}>()
    const [audio, setAudio] = useState<HTMLAudioElement>()
    /* const [avatarUrl, setAvatarUrl] = useState('') */
    const [audioBuffer, setAudioBuffer] = useState<any>()
    const [loadingAudio, setLoadingAudio] = useState(false)
    const [messageIndexReproducing, setMessageIndexReproducing] = useState(0)
    const {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
        mediaRecorder
      } = useAudioRecorder();
    const [audioToSend, setAudioToSend] = useState<any>()

    const [openInputModal, setOpenInputModal] = useState(false)
    

    /* const [cache, setCache] = useState() */

    const [enableAudio, setEnableAudio] = useState('true')

    const [messageQuota, setMessageQuota] = useState('')

    const [quota, setQuota] = useState({
        limit: 0,
        remaining: 0
    })

    const [showQuota, setShowQuota] = useState(false)

    useEffect(() => {
        if (!isAuth) {
            if (audio) {
                console.log(audio)
                audio.pause()
                setAudio(undefined)
            }
        }
    }, [isAuth])


    useEffect(() => {
        if (messageAudio) {
            const button = document.getElementById(`message_audio_${messageAudio.messageId}`)
            button?.click()
            setMessageAudio(undefined)
        }
    }, [messageAudio])

    useEffect(() => {
        if (enableAvatar) {
            const element: HTMLVideoElement = document.getElementById('container-avatar') as HTMLVideoElement
            console.log(element)
            if (element) {
                element.muted = true
                element.loop = true
                setAvatarElement(element)
            }
        } else {
            if (avatarElement) {
                avatarElement.pause()
                setAvatarElement(undefined)
            }
        }
    }, [enableAvatar])

    useEffect(() => {
        if (waitResponse) {
            const el = document.getElementById('chatMain')
            if (el) {
                el.scrollTop = el.scrollHeight
            }
        }
    }, [waitResponse])

    useEffect(() => {
        if (audio) {
            
            console.log('Nuevo audio', audio)
            audio.crossOrigin = "anonymous";
            audio.play().then(() => {
                console.log('Audio reproduciendose')
            }).catch(err => {
                console.error(err)
            })

            audio.addEventListener('playing', () => {
                setAvatarSpeaking(true)
                playSpeackAvatar()
            })
            audio.addEventListener('pause', () => {
                setAvatarSpeaking(false)
                stopSpeackAvatar()
                messagesToUse.forEach((message) => {
                    message.reproducing = false
                })
            })
            audio.addEventListener('ended', () => {
                setAvatarSpeaking(false)
                stopSpeackAvatar()
                messagesToUse.forEach((message) => {
                    message.reproducing = false
                })
            })
            
        }
    }, [audio])

    useEffect(() => {
        if (!recordingBlob) return;
        if (recordingBlob) {
            const file: File = blobToFile(recordingBlob, `${Date.now()}.webm`)
            console.log(file)
            setAudioToSend(file)
        }
    }, [recordingBlob])

    useEffect(() => {
        if (audioToSend) {
            addMessage()
        }
    }, [audioToSend])
      

    const drawAudio = (analyser: AnalyserNode) => {
        const canvas = document.querySelector("canvas");
        const ctx = canvas!.getContext("2d");
        canvas!.width = innerWidth;
        canvas!.height = 50;
        ctx!.fillRect(0, 0, canvas!.width, canvas!.height);
        const WIDTH = canvas!.width;
        const HEIGHT = canvas!.height;      
        requestAnimationFrame(() => drawAudio(analyser))
        const bufferLength = analyser.frequencyBinCount
        const dataArray = new Uint8Array(bufferLength)
        const barWidth = (WIDTH / bufferLength)
        let x = 0
        analyser.getByteFrequencyData(dataArray)
        ctx!.fillStyle = "#fff"
        ctx!.fillRect(0, 0, WIDTH, HEIGHT)
        dataArray.forEach((decibel, index) => {
            const c = index / bufferLength
            const r = decibel + 25 * c
            const g = 250 * c
            const b = 250
            ctx!.fillStyle = `rgb(${r}, ${g}, ${b})`
            ctx!.fillRect(x, HEIGHT - decibel, barWidth, decibel)
            x += barWidth + 1
        })

    }

    useEffect(() => {
        /* console.log(avatarElement, nameAvatar) */
        if (avatarElement && nameAvatar) {
            avatarElement.src = `/assets/videos/avatar-${nameAvatar}-standby.webm`
            if (avatarElement.src) {
                setTimeout(() => {
                    console.log('avatar play')
                    avatarElement.play()
                    const promise = avatarElement.play();
                    if(promise !== undefined){
                        promise.then(() => {
                            // Autoplay started
                        }).catch(error => {
                            // Autoplay was prevented.
                            avatarElement.muted = true;
                            avatarElement.play();
                        });
                    }

                }, 1000);
            }
        }
    }, [nameAvatar, avatarElement])

/*     useEffect(() => {
        if (avatarElement && avatarUrl) {
            avatarElement.src = avatarUrl
            if (avatarElement.src) {
                setTimeout(() => {
                    console.log('avatar play')
                    avatarElement.play()
                }, 1000);
            }
        }
    }, [avatarUrl]) */

    const stopSpeackAvatar = () => {
        if (avatarElement) {
            avatarElement.muted = true
            avatarElement.pause()
            avatarElement.src = `/assets/videos/avatar-${nameAvatar}-standby.webm`
            setTimeout(() => {
                avatarElement.play()
                if (audio && audio.played) {
                    audio.pause()
                    setAudio(undefined)
                }
                setAvatarSpeaking(false)
            }, 100);
        }
    }

    const playSpeackAvatar = () => {
        if (avatarElement) {
            avatarElement.pause()
            avatarElement.src = `/assets/videos/avatar-${nameAvatar}-speak.webm`
            avatarElement.play()
            setAvatarSpeaking(true)
            setAvatarPaused(false)
        }
    }

    useEffect(() => {
        if (messagesToUse) {
            console.log(messagesToUse)
            if (messagesToUse.length > 0) {
                const el = document.getElementById('chatMain')
                if (el) {
                    el.scrollTop = el.scrollHeight
                }
                if (messagesToUse.length === 1) {
                    const response = messagesToUse[0]
                    const newMessage = {
                        id: response.id,
                        message: '',
                        user: '#bot',
                        reference: [] ,
                        isNew: true,
                        removedBy: [],
                        audio: response.audio,
                    }
                    let textIndex = 0
                    const text : string = response.message
                    setTimeout(() => {
                        activateAudio(response)
                    }, 1000);
                    setWaitWhileMessage(false)
                    const interval = setInterval(() => {
                        textIndex = textIndex + 1
                        const newText = text.replace(/(?<![{[?}\]])\[(?!\s)(.+?)\]/g, '').substring(0, textIndex)
                        const el = document.getElementById('chatMain')
                        const paragraph = document.getElementById(`messageText_${newMessage.id}`)
                        if (paragraph) {
                            paragraph.innerHTML = urlify(`<p>${newText.replace(/(?<![{[?}\]])\*(?!\s)(.+?)\*/g, '<b>$1</b>')}</p>`
                            .replaceAll('\n* ', '\n- ')
                            .replaceAll('* <b>', '- <b>')
                            .replaceAll('<b>*', '<b>')
                            .replaceAll('</b>*', '</b>'))
                            .replaceAll('(<a', '<a')
                            .replaceAll(')</a>', '</a>')
                            .replaceAll(')"', '"')
                            if (el) {
                                el.scrollTop = el.scrollHeight
                            }
                            if (textIndex === text.length) {
                                clearInterval(interval)
                            }
                        }
                    }, 20)
                } else {
                    messagesToUse.forEach((message, index) => {
                        const newText = message.message.replace(/(?<![{[?}\]])\[(?!\s)(.+?)\]/g, '')
                        const paragraph = document.getElementById(`messageText_${message.id}`)
                        if (paragraph) {
                            paragraph.innerHTML = urlify(`<p>${newText.replace(/(?<![{[?}\]])\*(?!\s)(.+?)\*/g, '<b>$1</b>')}</p>`
                            .replaceAll('\n* ', '\n- ')
                            .replaceAll('* <b>', '\n- <b>')
                            .replaceAll('<b>*', '<b>')
                            .replaceAll('</b>*', '</b>'))
                            .replaceAll('(<a', '<a')
                            .replaceAll(')</a>', '</a>')
                            .replaceAll(')"', '"')
                            if (el) {
                                el.scrollTop = el.scrollHeight
                            }
                        }
                    })
                }
            }
        }
    }, [messagesToUse])

    useEffect(() => {
        if (messages) {
            setMessagesToUse(messages)
        }
    }, [messages])


    const closeDocumentReference = () => {
        setOpenFileReader(false)
    }

    const newMessageData = (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        if (openInputModal) {
            setOpenInputModal(false)
        }
        addMessage()
    }

    const urlify = (text: string) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, (url) => {
          return '<a style="color: blue;" target="_blank" href="' + url + '">' + url + '</a>';
        })
    }

    const addMessage = async () => {
        setWaitWhileMessage(true)
        if (message.length > 0) {
            const newMessage = {
                id: Date.now(),
                message: message,
                user: userInvited ? `${userInvited.name}` : initials,
                isNew: true,
                removedBy: [],
            }
            const messagesCache = [...messagesToUse!, newMessage]
            setMessagesToUse(messagesCache)
            setMessage('')
            setTimeout(() => {
                const el = document.getElementById('chatMain')
                if (el) {
                    el.scrollTop = el.scrollHeight
                    const elMessage1 = document.getElementById(`messageText_${newMessage.id}`)
                    if (elMessage1) {
                        elMessage1.innerHTML = `<p>${newMessage.message}</p>`
                        if (elMessage1) {
                            elMessage1.style.visibility = 'hidden'
                            elMessage1.style.opacity = '0'
                            setTimeout(() => {
                                elMessage1.style.visibility = 'visible'
                                elMessage1.style.opacity = '1'
                                elMessage1.style.transition = '500ms'
                            }, 100);
                        }
                    }
                }
            }, 100)
            setWaitResponse(true)
            try {
                let response : any
                if (audioToSend) {
                    response = await audioChat(audioToSend, userId)
                } else {
                    response = await chat(message, userId, enableAudio)
                }
                if (response.messageSaved) {
                    /* const indexOfMessage = messagesCache.findIndex(m => m.id === response.messageSaved.id) */
                    messagesCache[messagesCache.length - 1] = {
                        ...messagesCache[messagesCache.length - 1],
                        message: response.messageSaved.content,
                    }
                }
                setAudioToSend(undefined)
                setWaitResponse(false)
                if (response.newMessage) {
                    const newMessage2 = {
                        id: response.newMessage.id,
                        message: '',
                        user: '#bot',
                        reference: [] ,
                        isNew: true,
                        removedBy: [],
                        audio: response.newMessage.urlAudio,
                        reproducing: false
                    }
                    /* setAvatarUrl(response.newMessage.urlAvatar) */
                    const messagesCahe2 = [...messagesCache, newMessage2]
                    setMessagesToUse(messagesCahe2)
                    let textIndex = 0
                    const text : string = response.newMessage.content
                    if (text) {
                        messagesCahe2[messagesCahe2.length - 1] = {
                            ...messagesCahe2[messagesCahe2.length - 1],
                            message: text,
                        }
                        /* messagesCahe2[messagesCahe2.length - 1].message = text */
                    }
                    setWaitWhileMessage(false)
                    const interval = setInterval(() => {
                        textIndex = textIndex + 1
                        const newText = text.replace(/(?<![{[?}\]])\[(?!\s)(.+?)\]/g, '').substring(0, textIndex)
                        const el = document.getElementById('chatMain')
                        const paragraph = document.getElementById(`messageText_${newMessage2.id}`)
                        if (paragraph) {
                            paragraph.innerHTML = urlify(`<p>${newText.replace(/(?<![{[?}\]])\*(?!\s)(.+?)\*/g, '<b>$1</b>')}</p>`
                            .replaceAll('\n* ', '\n- ')
                            .replaceAll('* <b>', '- <b>')
                            .replaceAll('<b>*', '<b>')
                            .replaceAll('</b>*', '</b>'))
                            .replaceAll('(<a', '<a')
                            .replaceAll(')</a>', '</a>')
                            .replaceAll(')"', '"')
                            if (el) {
                                el.scrollTop = el.scrollHeight
                            }
                            if (textIndex === text.length) {
                                clearInterval(interval)
                            }
                        }
                    }, 20)
                }
                
            } catch (error: any) {
                console.log('Error: ', error)
                setWaitResponse(false)
                
                const newMessage2 = {
                    id: Date.now(),
                    message: '',
                    user: '#bot',
                    isNew: true,
                    removedBy: []
                }
                const messagesCahe2 = [...messagesCache, newMessage2]
                setMessagesToUse(messagesCahe2)
                let textIndex = 0
                const text : string = error.response ? (((error.response.data && !error.response.data.message) ? 'Error data!' : (error.response.data.message && error.response.data.message.includes('timeout')))
                ? 'Error timeot!'
                : ( (error.response.data.message.includes('connect ECONNREFUSED')||(error.response.data.message.includes('ETIMEDOUT'))) ? t('homePage:cantConnectServer') : t('homePage:initialText'))) : error.message
                const fileSelectedCache = fileSelected
                if (fileSelectedCache) {
                    if (text) {
                        messagesCahe2[messagesCahe2.length - 1].message = text
                        fileSelectedCache.messages = messagesCahe2
    
                    }
                    if (avatarWeb) {
                        avatarWeb.addMessage(text)
                        avatarWeb.processMessages()
                    }
                    setWaitWhileMessage(false)
                    const interval = setInterval(() => {
                        textIndex = textIndex + 1
                        const newText = text.substring(0, textIndex)
                        const el = document.getElementById('chatContainerData')
                        const paragraph = document.getElementById(`messageText_${newMessage2.id}`)
                        if (paragraph) {
                            paragraph.innerHTML = `<p>${newText.replace(/(?<![{[?}\]])\*(?!\s)(.+?)\*/g, '<b>$1</b>')}</p>`
                                .replaceAll('\n* ', '\n- ')
                                .replaceAll('* <b>', '- <b>')
                                .replaceAll('<b>*', '<b>')
                                .replaceAll('</b>*', '</b>')
                        }
                        if (el) {
                            el.scrollTop = el.scrollHeight
                        }
                        if (textIndex === text.length) {
                            clearInterval(interval)
                            setMessagesToUse(messagesCahe2)
                        }
                    }, 20)
                }

                
            }
        }
    }

    const blobToFile = (theBlob: Blob, fileName:string): File => {
        const b: any = theBlob;
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        b.lastModifiedDate = new Date();
        b.name = fileName;
          
        //Cast to a File() type
        return theBlob as File;
    }      

    useEffect(() => {
        if (transcript.length > 0) {
            setAudioMessage(transcript)
        }
    }, [transcript])

    useEffect(() => {
        if (!listening) {
            if (audioMessage.length > 0) {
                setMessage(audioMessage)
                setTimeout(() => {
                    addMessage()
                }, 500);
            }
            stopAudioMessage()
        }
    }, [listening])

    const startAudioMessage = () => {
        setAudioMessage('')
        SpeechRecognition.startListening()
        startRecording()
    }

    const stopAudioMessage = () => {
        SpeechRecognition.stopListening();
        setMessage(audioMessage)
        stopRecording()
    }

    const writeMessage = (e: any) => {
        setMessage(e.target.value)
    }

    const desactivateAudio = (message: MessageFormat) => {
        message.reproducing = false
        if (avatarSpeaking) {
            stopSpeackAvatar()
        }
    }
    
    const activateAudio = (message: MessageFormat) => {
        console.log(message)
        if (avatarSpeaking) {
            stopSpeackAvatar()
        }
        setTimeout(() => {
            messagesToUse.forEach((me) => {
                me.reproducing = false
            })
            message.reproducing = true
            const audioCache = new Audio(message.audio);
            setAudio(audioCache)
        }, 500);
    }

    return (
        <div className='homeContainer'>
            {(fileSelected && referenceSelected) && <PDFModal 
                open={openFileReader}
                closeModal={closeDocumentReference}
                pdfFile={fileSelected.url}
                loading={false}
                reference={referenceSelected}
            />}

            {
                openInputModal && <TextInputModal
                open={openInputModal}
                handleClose={() => {setOpenInputModal(false)}}
                inputText={writeMessage}
                text={message}
                newMessageData={newMessageData}
                />
            }
        
            <IonGrid>
                <IonRow>
                    {enableAvatar && <IonCol sizeXl={'6'} sizeLg={'6'} sizeMd={'6'} sizeSm={'12'} sizeXs={'12'}>
                        <IonRow>
                            <IonCol />
                            <IonCol size='8'>
                                <div id={'content-avatar'}>
                                {/* <iframe
                                    
                                    width="853"
                                    height="480"
                                    allow="autoplay"
                                    src="https://player.vimeo.com/video/23190487&amp;muted=1&amp;autoplay=1" /> */}
                                    <video poster={`/k-eternal/images/${nameAvatar}.png`} loop={true} id={'container-avatar'} width={'100%'}/>
                                </div>
                            </IonCol>
                            <IonCol />
                        </IonRow>
                    </IonCol>}
                    <IonCol className='margin-top-10' sizeXl={enableAvatar ? '6' : '12'} sizeLg={enableAvatar ? '6' : '12'} sizeMd={enableAvatar ? '6' : '12'} sizeSm='12' sizeXs='12'>
                        <div id={'chatMain'} className={ `chatMain ${isSmartPhone ? (enableAvatar ? 'chatContainerMobileWithAvatar' : 'chatContainerMobileWithOutAvatar') : 'chatContainerHeight'}`}>
                            <div className={`chatContainer`}>
                                <div className='chatContainerData' id='chatContainerData'>
                                    {
                                        (messagesToUse.length > 0 ) ? messagesToUse.map((message, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className='chat-message'>
                                                    <div 
                                                        className={
                                                            message.user === '#bot' ? 'chatBot-keternal-padding' : 'chatUser-keternal-padding'
                                                        }
                                                    >
                                                        <div style={{paddingLeft: 24.47, fontFamily: 'Montserrat'}}>
                                                            <p style={{margin: 0, color: '#8A898E'}}>{(message.user === '#bot') ? 'Mario Cañete' : `${user?.name} ${user?.lastName}`}</p>
                                                        </div>
                                                        <div id={`${message.id}`} className={(message.user === '#bot') ? 'chatBot-keternal' : 'chatUser-keternal'}>
                                                            <div style={{width:'100%'}}>
                                                                <div className='messageText' id={`messageText_${message.id}`} />
                                                                {
                                                                    (message.user === '#bot' && message.audio) && 
                                                                    <IonToolbar style={{'--background': 'transparent'}}>
                                                                        <IonButtons slot={'end'} >
                                                                            <IonButton
                                                                                id={`message_audio_${message.id}`}
                                                                                slot='icon-only'
                                                                                onDoubleClick={() => {console.log('Double click')}}
                                                                                onClick={() => {
                                                                                    const button: any = document.getElementById(`message_audio_${message.id}`)
                                                                                    if (button) {
                                                                                        button.disabled = true
                                                                                        setTimeout(() => {
                                                                                            button.disabled = false
                                                                                        }, 500);
                                                                                    }
                                                                                    message.reproducing ?
                                                                                    desactivateAudio(message)
                                                                                    :
                                                                                    activateAudio(message)
                                                                                }}>
                                                                                <IonIcon icon={message.reproducing ? stop : barcodeOutline} />
                                                                            </IonButton>
                                                                        </IonButtons>
                                                                    </IonToolbar>
                                                                }                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        !unableChat ? <div id={'spinner'} style={{width: '100%', paddingLeft: 16}}>
                                            <IonSpinner name='dots' /> <p>Iniciando conversación con {user?.name} {user?.lastName}</p>
                                        </div>
                                        :
                                        <div id={'spinner'} style={{width: '100%', paddingLeft: 16}}>
                                            <p>Usuario inhabilitado para conversar con el avatar</p>
                                        </div>
                                    }
                                    {
                                        waitResponse
                                        &&
                                        <div id={'spinner'} style={{width: '100%', paddingLeft: 16}}>
                                            <IonSpinner name='dots' /> <p>{waitAudio.stata && waitAudio.time}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    {
                        isSmartPhone ? 
                        <div className='textContainerSmartPhone' style={{padding: '0px 10px'}}>
                            {/* <form onSubmit={newMessageData}> */}
                                <div className={'textItem'}>
                                    {
                                        listening
                                        ?
                                        <div className='textItemInputs'>
                                            <p>
                                                {
                                                    audioMessage.length === 0
                                                    ?
                                                    'Recording'
                                                    :
                                                    audioMessage
                                                }
                                            </p>
                                        </div>
                                        :
                                        <div
                                            onClick={() => {setOpenInputModal(true)}}
                                            style={{width: '100%', height: '50%'}}
                                            className='textItemInputs'
                                        >
                                            <p>{message}</p>
                                        </div>}
                                    <button
                                        disabled={avatarSpeaking || unableChat}
                                        style={{position: 'absolute', right: 20, backgroundColor: 'transparent', top: 20, height: 25, width: 25}}
                                        onClick={() => {(message.length === 0) ? (!listening ? startAudioMessage() : stopAudioMessage()) : newMessageData()}}
                                        type={(message.length > 0) ? 'submit' : 'button'}
                                    >
                                        <img src={message.length === 0 ? Mic : Send} alt="" />
                                    </button>
                                </div>
                            {/* </form> */}
                        </div>
                        :
                        <div className='textContainer'>
                        <form onSubmit={newMessageData}>
                            <div className={'textItem'}>
                                {
                                    listening
                                    ?
                                    <div className='textItemInputs'>
                                        <p>
                                            {
                                                audioMessage.length === 0
                                                ?
                                                'Recording'
                                                :
                                                audioMessage
                                            }
                                        </p>
                                    </div>
                                    :
                                    <IonInput
                                    disabled={avatarSpeaking || unableChat}
                                    className='textItemInputs'
                                    onIonInput={writeMessage}
                                    value={message}
                                />}
                                <button
                                    disabled={avatarSpeaking || unableChat}
                                    style={{position: 'absolute', right: 20, backgroundColor: 'transparent', top: 20, height: 25, width: 25}}
                                    onClick={() => {(message.length === 0) && (!listening ? startAudioMessage() : stopAudioMessage())}}
                                    type={(message.length > 0) ? 'submit' : 'button'}
                                >
                                    <img src={message.length === 0 ? Mic : Send} alt="" />
                                </button>
                            </div>
                        </form>
                        </div>
                    }
                </IonRow>
            </IonGrid>
            {/* <div style={{width: '100%', height: 50, backgroundColor: 'blue'}}>
                <canvas width="100%" height="100%"></canvas>
            </div> */}
        </div>
    )
}

export default ChatContainer
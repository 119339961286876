import axios from 'axios'
import { UserInterface, UserOtherData } from '../interfaces/Auth.interface'

const url = window.location.host.split('.')[0] === 'qa' ? process.env.REACT_APP_SERVER_URL_QA : process.env.REACT_APP_SERVER_URL 

const getUsers = async () => {
    const response = await axios.get(url+'/api/users/getUsers')
    return response.data
}

const getUsersByCompany = async (companyId: string) => {
    const response = await axios.get(`${url}/api/users/getUsersByCompany/${companyId}`)
    return response.data
}

const createUser = async (user: UserInterface) => {
    const response = await axios.post(`${url}/api/users/createUser`, user)
    return response.data
}

const editUser = async (user: UserInterface, file?: any) => {
    console.log(user)
    const formData = new FormData()
    formData.append('user', JSON.stringify(user))
    if (file) {
        formData.append('files', file, file.name)
    }
    const response = await axios.post(`${url}/api/users/editUser`, formData)
    return response.data
}

const editUserWithOtherData = async (user: UserInterface, otherDatas: UserOtherData, file?: any) => {
    const formData = new FormData()
    formData.append('user', JSON.stringify(user))
    formData.append('otherDatas', JSON.stringify(otherDatas))
    if (file) {
        formData.append('files', file, file.name)
    }
    const response = await axios.post(`${url}/api/users/editUserWithOtherData`, formData)
    return response.data
}

const deleteUser = async (_id: string) => {
    const response = await axios.get(`${url}/api/users/deleteUser/${_id}`)
    return response.data
}

const getUserById = async (id: string) => {
    const response = await axios.post(`${url}/api/users/getUserById`, {id: id})
    return response.data
}

const editPassword = async (currentPassword: string, newPassword: string, userId: string) => {
    const response = await axios.post(`${url}/api/users/editPassword`, {currentPassword, newPassword, userId})
    return response.data
}

export default {
    getUsers,
    getUsersByCompany,
    createUser,
    editUser,
    editPassword,
    deleteUser,
    editUserWithOtherData
}

import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonRow, IonToolbar } from "@ionic/react"
import { useEffect, useState } from "react"
import { useUsersContext } from "../../context/Users.context"
import { close } from "ionicons/icons"
import { Divider } from "@mui/material"

export const UserProfileComponent = ({handleClose}:{handleClose?: () => void}) => {

    const {userSelected, editUserWithOtherData} = useUsersContext()
    const [userProfileData, setUserProfileData] = useState<any[]>([])

    useEffect(() => {
        console.log(userSelected)
        if (userSelected) {
            const {
                city,
                email,
                lastName,
                name,
                birthdate,
                phone,
                nacionality,
                gender,
                section,
                userOtherData,
            } = userSelected as any
            const user: any = {
                city,
                email,
                lastName,
                name,
                birthdate,
                phone,
                nacionality,
                gender,
                speciality: section[0].name,
                institutions: userOtherData ? userOtherData.institutions[0] : '',
                divider: '',
                divider2: '',
                graduationUniversity: userOtherData ? userOtherData.graduationUniversity : '',
                maritalStatus: userOtherData ? userOtherData.tastes.maritalStatus : '',
                socialProfile: userOtherData ? userOtherData.tastes.socialProfile : '',
                socialStratum: userOtherData ? userOtherData.tastes.socialStratum : '',
                personalAppearance: userOtherData ? userOtherData.tastes.personalAppearance : '',
                politicalTrend: userOtherData ? userOtherData.tastes.politicalTrend : '',
                physicalBuild: userOtherData ? userOtherData.tastes.physicalBuild : '',
                pet: userOtherData ? userOtherData.tastes.pet : '',
                hobbie: userOtherData ? userOtherData.tastes.hobbie : '',
                collectibles: userOtherData ? userOtherData.tastes.collectibles : '',
                foods: userOtherData ? userOtherData.tastes.foods : '',
                music: userOtherData ? userOtherData.tastes.music : '',
                sea: userOtherData ? (userOtherData.tastes.sea ? 'Si' : 'No') : '',
                montain: userOtherData ? (userOtherData.tastes.montain ? 'Si' : 'No') : '',
                telemedicine: userOtherData ? (userOtherData.workData.telemedicine ? 'Si' : 'No') : '',
                healthForecast: userOtherData ? (userOtherData.workData.healthForecast) : null,
            }
            const userProfileDataCache = Object.keys(user).map((key: string) => {
                return [key, user[key], getIndex(key)![0], getIndex(key)![1]]
            })
            setUserProfileData(
                userProfileDataCache
                .sort((a: any[], b: any[]) => {
                    if (a[2] < b[2]) {
                        return -1
                    }
                    if (a[2] > b[2]) {
                        return 1
                    }
                    return 0
                })
            )
        }
    }, [userSelected])

    const getIndex = (value: string) => {
        if (value==='name') {
            return [0, 'text']
        } else if (value === 'lastName') {
            return [1, 'text']
        } else if (value === 'gender') {
            return [2, 'text']
        } else if (value === 'birthdate') {
            return [3, 'date']
        } else if (value === 'nacionality') {
            return [4, 'text']
        } else if (value === 'phone') {
            return [5, 'text']
        } else if (value === 'email') {
            return [8, 'text']
        } else if (value === 'city') {
            return [9, 'text']
        } else if (value === 'speciality') {
            return [16, 'text']
        } else if (value === 'institutions') {
            return [18, 'text']
        } else if (value === 'telemedicine') {
            return [18, 'text']
        } else if (value === 'divider') {
            return [19, 'divider']
        } else if (value == 'graduationUniversity') {
            return [19, 'text']
        } else if (value == 'maritalStatus') {
            return [20, 'text']
        } else if (value === 'socialProfile') {
            return[22, 'text']
        } else if (value === 'socialStratum') {
            return[23, 'text']
        } else if (value === 'personalAppearance') {
            return [24, 'text']
        } else if (value === 'politicalTrend') {
            return [25, 'text']
        } else if (value==='physicalBuild') {
            return [26, 'text']
        } else if (value==='pet') {
            return [27, 'text']
        } else if (value==='hobbie') {
            return [28, 'text']
        } else if (value==='collectibles') {
            return [29, 'text']
        } else if (value==='foods') {
            return [30, 'text']
        } else if (value==='music') {
            return [31, 'text']
        } else if (value==='sea') {
            return [32, 'text']
        } else if (value==='montain') {
            return [33, 'text']
        } else if (value === 'divider2') {
            return [34, 'divider']
        } else if (value==='healthForecast') {
            return [35, 'listItems']
        }
    }

    const translateValue = (value: string) => {
        if (value==='name') {
            return 'NOMBRE'
        } else if (value === 'lastName') {
            return 'APELLIDO'
        } else if (value === 'gender') {
            return 'GÉNERO'
        } else if (value === 'birthdate') {
            return 'FECHA DE NACIMIENTO'
        } else if (value === 'phone') {
            return 'TELÉFONO'
        } else if (value === 'email') {
            return 'EMAIL'
        } else if (value === 'city') {
            return 'COMUNA'
        } else if (value === 'nacionality') {
            return 'NACIONALIDAD'
        } else if (value === 'speciality') {
            return 'ESPECIALIDAD'
        } else if (value === 'institutions') {
            return 'INSTITUCIONES'
        } else if (value === 'telemedicine') {
            return 'TELEMEDICINA'
        } else if (value === 'graduationUniversity') {
            return 'UNIVERSIDAD DE EGRESO'
        } else if (value === 'maritalStatus') {
            return 'ESTADO CIVIL'
        } else if (value === 'socialProfile') {
            return 'PERFIL SOCIAL'
        } else if (value === 'socialStratum') {
            return 'ESTRATO SOCIAL'
        } else if (value === 'personalAppearance') {
            return 'ASPECTO PERSONAL'
        } else if (value === 'politicalTrend') {
            return 'TENDENCIA POLÍTICA'
        } else if (value==='physicalBuild') {
            return 'CONTEXTURA FÍSICA'
        } else if (value==='pet') {
            return 'MASCOTA'
        } else if (value==='hobbie') {
            return 'HOBBIE'
        } else if (value==='collectibles') {
            return 'OBJETOS DE COLECCIÓN'
        } else if (value==='foods') {
            return 'COMIDAS FAVORITAS'
        } else if (value==='music') {
            return 'MÚSICA FAVORITA'
        } else if (value==='sea') {
            return '¿TE GUSTA EL MAR?'
        } else if (value==='montain') {
            return '¿TE GUSTA LA MONTAÑA?'
        }
    }

    useEffect(() => {
        console.log(userProfileData)
    }, [userProfileData])

    const getRole = (value: string | undefined) => {
        if (value==='doctor') {
            return 'DOCTOR'
        } else {
            if (value) {
                return value.toUpperCase()
            } else {
                return 'NO PROFILE DATA'
            }
        }
    }

    const traductorPrevision = (value: string) => {
        if (value === 'banmedica') {
            return 'BANMEDICA'
        } else if (value === 'consalud') {
            return 'CONSALUD'
        } else if (value === 'cruzBlanca') {
            return 'CRUZ BLANCA'
        } else if (value === 'fonasa') {
            return 'FONASA'
        } else if (value === 'nuevaMasVida') {
            return 'NUEVA MAS VIDA'
        } else if (value === 'vidaTres') {
            return 'VIDA TRES'
        } else {
            return ''
        }
    }

    const PrevisionImagen = ({name}: {name: string}) => {
        return (
            <div
                style={{
                    borderRadius: 20,
                    boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.15)',
                    minHeight: 73,
                    marginBottom: 10,
                    maxWidth: 208,
                    marginLeft: 'auto',
                    marginRight: 0
                }}
            >
                <IonGrid>
                    <IonRow>
                        <IonCol size="4">
                            <div style={{position: 'relative', height: 73, width: '100%', padding: '0px 10px'}}>
                                <img src={`./assets/prevision/${name}.png`} className="vertical-center" />
                            </div>
                        </IonCol>
                        <IonCol size="8" >
                            <div style={{position: 'relative', height: 73, width: '100%', padding: '0px 10px'}}>
                                <p className="vertical-center" style={{margin: 0}}>{traductorPrevision(name)}</p>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        )
    }

    const changeValue = (e: any) => {
        const {value, type, name} = e.target
        console.log(value, type, name)
        if (type === 'text') {
            const userProfileDataCache = [...userProfileData]
            const dataFiltered = userProfileDataCache.findIndex((data, index) => {
                console.log(data[0] === name)
                if (data[0] === name) {
                    console.log(index)
                    return index
                }
            })
            console.log(dataFiltered)
            if (dataFiltered > -1) {
                userProfileDataCache[dataFiltered][1] = value
                setUserProfileData([...userProfileDataCache])
            } else if (dataFiltered === -1) {
                userProfileDataCache[0][1] = value
                setUserProfileData([...userProfileDataCache])
            }
        }
    }

    const editUserData = async () => {
        const userData: any = {}
        const userOtherData: any = {}

        userProfileData.forEach((value) => {
            if (
                value[0] === 'name' || 
                value[0] === 'lastName' || 
                value[0] === 'birthdate' || 
                value[0] === 'city' || 
                value[0] === 'email' || 
                value[0] === 'gender'
            ) {
                userData[value[0]] = value[1]
            }
            if (
                value[0] === 'collectibles' || 
                value[0] === 'montain' || 
                value[0] === 'sea' || 
                value[0] === 'hobbie' || 
                value[0] === 'politicalTrend' || 
                value[0] === 'personalAppearance' || 
                value[0] === 'physicalBuild' || 
                value[0] === 'pet' || 
                value[0] === 'maritalStatus' || 
                value[0] === 'foods' || 
                value[0] === 'music' || 
                value[0] === 'socialStratum' || 
                value[0] === 'socialProfile'
            ) {
                if (!userOtherData['tastes']) {
                    userOtherData['tastes'] = {}
                }
                userOtherData['tastes'][value[0]] = value[1]
            }
            if (value[0] === 'institutions') {
                if (!userOtherData['institutions']) {
                    userOtherData['institutions'] = []
                }
                userOtherData['institutions'] = [value[1]]
            }
            if (value[0] === 'graduationUniversity') {
                if (!userOtherData['graduationUniversity']) {
                    userOtherData['graduationUniversity'] = ''
                }
                userOtherData['graduationUniversity'] = value[1]
            }
            if (
                value[0] === 'healthForecast'
            ) {
                if (!userOtherData['workData']) {
                    userOtherData['workData'] = {}
                }
                if (!userOtherData['workData']['healthForecast']) {
                    userOtherData['workData']['healthForecast'] = {}
                }
                userOtherData['workData']['healthForecast'] = value[1] 
            }
            if (value[0] === 'telemedicine') {
                if (!userOtherData['workData']) {
                    userOtherData['workData'] = {}
                }
                if (!userOtherData['workData']['telemedicine']) {
                    userOtherData['workData']['telemedicine'] = false
                }
                userOtherData['workData']['telemedicine'] = value[1] === 'No' ? false : true
            }
        })

        console.log(userData)
        console.log(userOtherData)

        const response = await editUserWithOtherData(userData, userOtherData)
        console.log(response)
        alert(`Usuario ${userData.name} ${userData.lastName} actualizado`)
        if (handleClose) {
            handleClose()
        }
    }
     

    return (
        <IonContent class="ion-padding">
            <IonToolbar style={{borderBottom: '3px solid #5E6F9D'}}>
                {<div style={{paddingLeft: 15}}>
                    <h3 style={{fontFamily: 'Poppins'}}>Perfil de Usuario</h3>
                    
                </div>}
                {handleClose && <IonButtons slot='end'>
                    <IonButton onClick={() => {handleClose()}}>
                        <IonIcon icon={close} />
                    </IonButton>
                </IonButtons>}
            </IonToolbar>
            <IonGrid style={{paddingLeft: 10, marginTop: 50}}>
                <IonRow>
                    <IonCol id={'image-profile-col'} sizeXl="2" sizeLg="2" sizeMd="6" sizeSm="12" sizeXs="12">
                        <div style={{padding: 10}}>
                            <div>
                                <img src={userSelected?.image ? userSelected.image : './assets/images/no_profile.webp'} style={{
                                    maxWidth: 120,
                                    maxHeight: 120,
                                    borderRadius: '50%'
                                }} alt="" />
                                <h1>
                                    <b>
                                    {userSelected?.name} <br />
                                    {userSelected?.lastName}
                                    </b>
                                </h1>
                                <div style={{backgroundColor: '#f1f1f1', padding: 5, textAlign: 'center', maxWidth: 100}}>
                                    <p style={{fontSize: 10, margin: 0, color: '#909090'}}>{getRole(userSelected?.roles[0].name)}</p>
                                </div>
                            </div>
                        </div>
                    </IonCol>
                    <IonCol id={'data-profile-col'} sizeXl="10" sizeLg="10" sizeMd="6" sizeSm="12" sizeXs="12">
                        <div style={{height: '65vh', overflowY: 'auto'}}>
                            <IonRow style={{width: '96%'}}>
                                {
                                    userProfileData.map((data, index)=> {
                                        if (data[0].includes('divider')) {
                                            return (
                                                <Divider id={`name-input-${data[0]}`} key={index} style={{width: '100%', margin: '30px 0px 0px 30px'}} />
                                            )
                                        } else if (data[0] === ("healthForecast")) {
                                            console.log(data[0])
                                            return (
                                                <div key={index} style={{width: '100%', paddingLeft: 50, marginTop: 30}}>
                                                    <p style={{fontSize: 10, color: '#ccc', margin: 0, marginBottom: 20}}>
                                                        ATIENDE POR:
                                                    </p>
                                                    <IonGrid style={{width: '100%'}}>
                                                        <IonRow>
                                                            {
                                                                (data[1] && Object.keys(data[1]).length > 0) && Object.keys(data[1]).map((key) => {
                                                                    if (data[1][key]) {
                                                                        return (
                                                                            <IonCol key={`${key}_`} style={{padding: '0px 10px'}} size="3">
                                                                                <PrevisionImagen
                                                                                    name={key}
                                                                                />
                                                                            </IonCol>
                                                                        )
                                                                    } else {
                                                                        null
                                                                    }
                                                                })
                                                            }
                                                        </IonRow>
                                                    </IonGrid>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <IonCol id={`name-input-${data[0]}`} key={index} sizeXl="4" sizeLg="4" sizeMd="6" sizeSm="12" sizeXs="12">
                                                    <div style={{padding: 10}}>
                                                        <p style={{fontSize: 10, color: '#ccc', margin: 0, marginBottom: 5}}>
                                                            {translateValue(data[0])}
                                                        </p>
                                                        <input
                                                            onChange={changeValue}
                                                            name={data[0]}
                                                            disabled={(data[3] === "date" || data[0] === 'email') ? true : false}
                                                            style={{border: '1px #C4C4C4 solid', borderRadius: 8, height: 48, width: '100%', padding: 10, color: '#909090'}}
                                                            type={data[3]}
                                                            value={data[3] === 'date' ? data[1] ? data[1].split('T')[0] : null : data[1]}
                                                            />
                                                    </div>
                                                </IonCol>
                                            )
                                        }
                                        
                                        
                                    })
                                }                            
                            </IonRow>
                        </div>
                        <div style={{position: 'relative', marginTop: 50, bottom: 0, left: 0, width: '100%', textAlign: 'right', paddingRight: 30}}>
                            <button /* disabled={true} */ onClick={editUserData} className="ion-activatable ripple-parent rectangle" style={{width: 120, height:48, marginLeft: 'auto', marginRight: 10, borderRadius: 10, backgroundColor: '#D4D5D9', color: '#fff'}}>
                                Editar usuario
                            </button>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    )
}
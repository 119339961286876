import { IonContent, IonModal, IonTextarea } from "@ionic/react"
import { Send } from "../icons"
import { useEffect, useRef } from "react"

export const TextInputModal = ({open, handleClose,inputText, text, newMessageData}:{open: boolean, handleClose: () => void, inputText: (e: any) => void, text: string, newMessageData: () => void}) => {
    const input = useRef<HTMLIonTextareaElement>(null);

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                input.current?.setFocus().then(() => {
                    input.current?.getInputElement().then(nativeInput => {
                        const len = text.length;
                        setTimeout(() => { nativeInput.setSelectionRange(len, len); }, 100);
                    })
                })
            }, 200);
        }
    }, [open])
    return (
        <IonModal
            isOpen={open}
            onWillDismiss={handleClose}
            /* backdropDismiss={false} */
            className='input-text-modal'
        >
            <IonContent class="ion-padding">
                <div className="textIteminModal">
                    <IonTextarea
                        id={'mytext'}
                        ref={input}
                        className='textItemInputs'
                        autoFocus={true}
                        style={{width: '100%', borderColor: 'transparent', padding: 0}}
                        value={text}
                        onIonChange={inputText}
                        onIonFocus={(e) => {console.log('FOCUS')}}
                        
                    />
                    <button
                        style={{position: 'absolute', right: 20, backgroundColor: 'transparent', top: 20, height: 25, width: 25}}
                        onClick={newMessageData}
                        /* type={(message.length > 0) ? 'submit' : 'button'} */
                    >
                        <img src={Send} alt="" />
                    </button>
                </div>
            </IonContent>
        </IonModal>
    )
}
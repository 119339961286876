import { createContext, useContext, useEffect, useState } from 'react'
import { io, Socket } from "socket.io-client";
import { useAuthContext } from './Auth.context';
import { useMessagesContext } from './Messages.context';

interface SocketType {
    notification: {
        isData: boolean,
        data: any
    },
    emitNotificationToUser: (data: any) => Promise<any>
    listenPDF: (userId: string, getDataPDF: any) => void
    waitAudio: any
    setWaitAudio: any
    messageAudio: any
    setMessageAudio: any
}

export const SocketContext = createContext<SocketType>({} as SocketType)

export const SocketProvider = (props: any) => {
    const {user} = useAuthContext()
    const {setMessageAudioUser} = useMessagesContext()
    const urlLocal = window.location.host.split('.')[0] === 'qa' ? process.env.REACT_APP_SERVER_URL_QA : process.env.REACT_APP_SERVER_URL
    console.log(urlLocal)
    const [notification, setNotification] = useState<{
        isData: boolean,
        data: any
    }>()
    const [socketLocal, setSocketLocal] = useState<Socket>()

    const [messageAudio, setMessageAudio] = useState<any>()

    const [waitAudio, setWaitAudio] = useState({
        state: false,
        time: ''
    })

    useEffect(() => {
        if (urlLocal) {
            setSocketLocal(io(urlLocal))
        }
    }, [urlLocal])

    useEffect(() => {
        if (socketLocal && user) {
            socketLocal!.on(`audio${user._id}`, data => {
                console.log(data)
                setMessageAudio(data)
            })
            socketLocal!.on(`messageAudio${user._id}`, data => {
                console.log(data.newUserMessage)
                const messageFromServer = data.newUserMessage
                setMessageAudioUser(messageFromServer)
            })
        }
    }, [socketLocal, user])

    const values = {
        notification,
        waitAudio,
        setWaitAudio,
        messageAudio,
        setMessageAudio
    } as SocketType

    return (
        <SocketContext.Provider value={values}>
        {props.children}
        </SocketContext.Provider>
    )
}

export const useSocketContext = () => useContext(SocketContext)